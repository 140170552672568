<template>
  <div>

    <el-container>
      <el-header style="background-color:#FFF;">
        <el-row class="pt-2 pb-2">
          <el-col :lg="20" :xs="14">
          <h3 style="line-height:1.8">Über DACY</h3>
          </el-col>
          <el-col :lg="2" :xs="4" style="line-height:50px; text-align:right">
            <el-button @click="$router.push({ name: 'dashboard' } )" class="mr-3 pl-3 pr-3 pt-2 pb-2" type="warning" icon="el-icon-s-home" ></el-button>
          </el-col>
          <el-col :lg="2" :xs="6" class="text-right align-content-end">
            <main-menu></main-menu>
          </el-col>
        </el-row>
      </el-header>
      <div class="w-100" style="background-color:#f39200; height:8px;"></div>
      <el-main>
        <el-row >
          <el-col :span="24">

            <el-card>

              <div class="mb-3 w-75">
              Das primäre Ziel der Software Dacy ist neben der vereinfachten Patientendokumentation die bequeme und zeitsparenden Informationssuche klinisch relevanter Informationen.<br/>
              Durch die schnelle und übersichtliche Verfügbarkeit von Informationen zum Patienten, seinen Diagnosen und den zugrunde liegenden Befunden, der Therapien sowie der darüber hinausgehenden Therapieoptionen, der Dokumentation des Verlaufs und den aufgetretenen Nebenwirkungen bzw. Komplikationen werden ärztlich Tätige im Praxisalltag deutlich entlastet und erhalten die Möglichkeit erhalten, Informationen in dem Moment, in dem sie anfallen, zeitsparend und strukturiert abzulegen.
              <br/>
                Die Dacy Knowledgebase bietet durch ihr innovatives Konzept eine klare Übersicht der Behandlungsoptionen bezogen auf Diagnose, Behandlungssituation, prädiktive Faktoren und Zulassungsstatus. Zudem verlinkt die Knowledgebase zu Fachinformationen und relevanten Publikationen.
              </div>
              <el-row>
                <el-col :span="12">
                  DACY GmbH<br/>
                  Viersenerstraße 55<br/>
                  41462 Neuss<br/>
                  <br/>
                  E-Mail<br/>
                  info@dacy.pro<br/>
                  <br/>
                  Geschäftsführer und verantwortlich für den Inhalt Dr. Christoph Losem
                  <br/><br/>
                  Registergericht<br/>
                  Amtsgericht Neuss
                  <br/>
                  <br/>
                  Handelsregister-Nummer HRB 22892<br/>
                </el-col>
                <el-col :span="12">
                </el-col>
              </el-row>

            </el-card>


          </el-col>
        </el-row>
      </el-main>
    </el-container>

  </div></template>

<script>
import MainMenu from "@/components/parts/MainMenu";

export default {
  name: "About",
  props: [],
  components: {
    MainMenu
  },
  data () {
    return {
      dacyVersion: process.env.VUE_APP_DACY_VERSION
    }
  },
  mounted: function () {
  },
  computed: {
  },
  watch: {
  },
  methods: {
}

}
</script>
<style scoped>
</style>
